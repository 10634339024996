import { React, Page, SEO, CenterWrapper, StyleSheet, View } from 'lib'
import { HTMLStylesDark, Theme } from 'app'
import { Item, PageTitle } from 'components'

import howToSigingUp from 'assets/how_to_siging_up.png'
import howToPermission from 'assets/how_to_permission.png'
import howToHome from 'assets/how_to_home.png'
import howToShield from 'assets/how_to_shield.png'
import howToJustGo from 'assets/how_to_just_go.png'
import howToUserPlus from 'assets/how_to_user_plus.png'
import howToStar from 'assets/how_to_star.png'
import howToSecuritySystem from 'assets/how_to_security_system.png'

export const howToUseData = [
  {
    title: 'Signing up to Becon',
    icon: howToSigingUp,
    slug: 'signing-up-to-becon',
    video: 'https://vimeo.com/808291710',
  },
  {
    title: 'Onboarding & permissions',
    icon: howToPermission,
    slug: 'onboarding-permissions',
    video: 'https://vimeo.com/808291780',
  },
  {
    title: 'Map & Homescreen Tour',
    icon: howToHome,
    slug: 'map-homescren-tour',
    video: 'https://vimeo.com/808291570',
  },
  {
    title: 'How to activate Becon for a journey',
    icon: howToShield,
    slug: 'activate-becon-for-a-journey',
    video: 'https://vimeo.com/808291379',
  },
  {
    title: 'How to use ‘Just Go’ mode',
    icon: howToJustGo,
    slug: 'using-just-go-mode',
    video: 'https://vimeo.com/808291473',
  },
  {
    title: 'How to add a Key Contact',
    icon: howToUserPlus,
    slug: 'how-to-add-a-key-contact',
    video: 'https://vimeo.com/814701746',
  },
  {
    title: 'How to assign Saved Places and home screen buttons',
    icon: howToStar,
    slug: 'how-to-assign-saved-places-&-home-screen-button',
    video: 'https://vimeo.com/818812328',
  },
  {
    title: 'How to create & edit safety modes',
    icon: howToSecuritySystem,
    slug: 'how-to-create-&-edit-safety-modes',
    video: 'https://vimeo.com/823367133',
  },
]

function HowToUse() {
  return (
    <Page whiteBackground>
      <SEO doNotTrack title={'Guidance'} description={'Video tutorials'}/>
      <CenterWrapper style={styles.wrapper} contentContainerStyle={styles.innerWrapper}>
        <PageTitle location='Guidance' title='Video Tutorials'/>
        <View style={styles.listWrapper}>
          {howToUseData?.map((data, index) => <Item key={data.title} lastItem={(howToUseData.length - 1) === index} {...data}/>)}
        </View>
      </CenterWrapper>
    </Page>
  )
}

const styles = StyleSheet.createComponentStyleSheet({
  wrapper: {
    ...Theme.flex,
    marginTop: Theme.spacing(20),
    marginBottom: Theme.spacing(5),
    [Theme.media.down('mid')]: {
      marginTop: Theme.spacing(12),
      marginBottom: Theme.spacing(5),
    },
  },
  listWrapper: {
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
  },
  innerWrapper: {
    ...HTMLStylesDark.plainText,
    gap: Theme.spacing(3),
    flexDirection: 'column',
  },
})

export default HowToUse
